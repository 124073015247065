import React, { Suspense } from 'react';
import Loading from '../components/global/loading';
import Reveal from '../components/ui/reveal';

const Pullquote = React.lazy(() => import('../components/article/pullquote'));
const ImageCaption = React.lazy(() => import('../components/article/imageCaption'));
const TeamCarousel = React.lazy(() => import('../components/basic/teamCarousel'));
const ContactCallout = React.lazy(() => import('../components/basic/contactCallout'));
const GridCallout = React.lazy(() => import('../components/basic/gridCallout'));
const IntroText = React.lazy(() => import('../components/basic/introText'));
const ArticleText = React.lazy(() => import('../components/article/articleText'));
const MailChimpForm = React.lazy(() => import('../components/basic/mailChimpForm'));
const BottomCallouts = React.lazy(() => import('../components/article/bottomCallouts'));
const MailChimpThankYou = React.lazy(() => import('../components/basic/mailChimpThankYou'));
const OneColMailChimpForm = React.lazy(() => import('../components/basic/oneColMailChimpForm'));
const TwoColMailChimpForm = React.lazy(() => import('../components/basic/twoColMailChimpForm'));
const ArticleList = React.lazy(() => import('../components/article/articleList'));
const Fellows = React.lazy(() => import('../components/article/fellows'));

const basicMapDataToComponents = (paragraphs, options, itemid) => {
  const addReveal = options && options.reveal;
  const isSSR = typeof window === "undefined";
  return (
    paragraphs &&
    paragraphs.map((p, i) => {
      switch (p.type) {
        case 'intro_text':
          return <IntroText key={`basicItem${p.type}${i}`} text={p.text} />;
        case 'textarea':
          return (
            <ArticleText key={`basicItem${p.type}${i}`}>
              <div dangerouslySetInnerHTML={{ __html: p.text }} />
            </ArticleText>
          );
        case 'pull_quote':

          return (
            <Pullquote
              key={`basicItem${p.type}${i}`}
              quoteText={p.text}
              captionText={p.byline_description}
            />
          );
        case 'single_image':
          return (
            <ImageCaption
              key={`basicItem${p.type}${i}`}
              containerWidth="widthNormal"
              src={p.image.sizes.fixed_width_sm}
              altText={p.image.alt_text}
              captionHead={p.image.title}
              captionText={p.image.caption}
            />
          );
        case 'team_carousel':
          return (
            <Reveal key={`basicItem${p.type}${i}`}>
              <TeamCarousel
                slides={p.items.map(item => ({
                  src: item.image ? item.image.sizes.original : '',
                  altText: item.image.alt_text,
                  captionHead: item.callout_heading,
                  captionSubtitle: item.callout_subtitle,
                  captionText: item.text,
                  twitter: item.twitter,
                  linkedin: item.linkedin,
                }))}
              />
            </Reveal>
          );
        case 'bottom_callouts':
          if (!p.items) {
              return (<div></div>);
          }
          const bottomCalloutsComponent = (
                <BottomCallouts
                  key={`bottomCallouts${i}`}
                  itemCallouts={p.items.map(item => ({
                    calloutHeading: item.title,
                    calloutSubtitle: item.callout_subtitle,
                    calloutPath: item.path,
                    cta_link: item.cta_link ? item.cta_link : '',
                    src: item.image
                      ? p.items.length > 1
                        ? item.image.sizes.original
                        : item.image.sizes.original
                      : '',
                    altText: item.image ? item.image.alt_text : '',
                  }))}
                />
          );
          const bottomCalloutsComponentKey = `basicItem${p.type}${i}`;

          return addReveal ? (
            <Reveal key={bottomCalloutsComponentKey + 'reveal'}>
              {bottomCalloutsComponent}
            </Reveal>
          ) : (
            bottomCalloutsComponent
          );
        case 'contact_callout':
          return (
            <ContactCallout
              key={`basicItem${p.type}${i}`}
              latitude={p.longitude}
              longitude={p.longitude}
              cta_link={p.cta_link}
              text={p.text}
            />
          );
        case 'grid_callout':
        case 'grid_text_callout':
          const componentKey = `basicItem${p.type}${i}`;
          const component = (
            <GridCallout
              key={componentKey}
              componentStyle={p.component_style}
              imageAlignmentType={p.image_alignment_type}
              imageWidthType={p.image_width_type}
              textVerticalAlign={p.text_vertical_align}
              label={p.label}
              title={p.title}
              text={p.text ? p.text : ''}
              cta_link={p.cta_link ? p.cta_link : ''}
              cta_color={p.cta_link ? 'white' : ''}
              path={p.path}
              imgSrc={p.image ? p.image.sizes.original : ''}
              imgSizePortrait={
                p.image ? p.image.sizes.fixed_height_portrait : ''
              }
              imgDimensions={
                p.image ? p.image.dimensions.fixed_height_portrait : ''
              }
              imgAltText={p.image ? p.image.alt_text : ''}
              imgTitle={p.image ? p.image.title : ''}
              imgCaption={p.image ? p.image.caption : ''}
              videoSrc={p.video ? p.video.url : null}
            />
          );

          return addReveal ? (
            <Reveal key={componentKey + 'reveal'}>
              {component}
            </Reveal>
          ) : (
            component
          );
        case 'mailchimp_form':
          return (
            <MailChimpForm
              key={`basicItem${p.type}${i}`}
              form_id={p.form_id}
              form_u={p.form_u}
            />
          );
				case 'mailchimp_thank_you':
          return (
          	<MailChimpThankYou
          		className="mailchimp_thank_you"
          		key={`basicItem${p.type}${i}`}
          		callout_heading={p.callout_heading}
          		callout_text_long={p.callout_text_long}
          		follow_us={p.follow_us}
          		itemid={itemid}
          	/>
          )
        case 'single_column_mailchimp_cta':
          return (          	
          	<OneColMailChimpForm
          		className="single_column_mailchimp_cta"
          		key={`basicItem${p.type}${i}`}
          		callout_heading={p.callout_heading}
          		callout_subtitle={p.callout_subtitle}
          		callout_subtitle_long={p.callout_subtitle_long}
          		callout_text={p.callout_text}
          		itemid={itemid}
          	/>
          )
        case 'two_column_mailchimp_cta':
          console.log(p)
          return (
          	<TwoColMailChimpForm
          		className="two_column_mailchimp_cta"
          		key={`basicItem${p.type}${i}`}
          		callout_heading={p.callout_heading}
          		callout_subtitle={p.callout_subtitle}
          		callout_text={p.callout_text}
          		first_column_heading={p.first_column_heading}
          		first_column_content={p.first_column_content}
              mailchimp_destination={p.mailchimp_destination}
          		itemid={itemid}
          	/>
          )
        case 'article_list':
          return (<ArticleList 
            key={`articleItem${p.type}${i}`}
            label={p.label}
            articles={p.items}
          />);
        case 'fellows':
          return (
            <Fellows
              linkBack={''}
              linkBackName={''}
            />
          );
        default:
          return null;
      }
    })
  ).map((paragraph, index) => {
    return(
      <>
        {!isSSR && (
            <Suspense key={`suspense${index}`} fallback={<Loading/>}>
              {paragraph}
            </Suspense>
        )}
      </>
    );
	});
};

export default basicMapDataToComponents;
